import Flickity from 'flickity';

const QuoteSlider = () =>
{
    const quoteSliderHolder = document.querySelectorAll('.quote-slider__holder');

    if ( quoteSliderHolder.length != 0 ) {
        quoteSliderHolder.forEach((slider) => {
            const flkty = new Flickity(slider, {
                cellSelector: '.quote-slider__slide',
                wrapAround: true,
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'center',
                autoPlay: false,
                draggable: true,
                adaptiveHeight: true
                // selectedAttraction: 0.2,
                // friction: 0.8
            });

            window.addEventListener( 'load', function() { flkty.resize() });

            const nextButtons = document.querySelectorAll('.quote-slider__next-button');
            nextButtons.forEach(button => {
                button.addEventListener('click', () => flkty.next());
            })
        });
    }
}

export default QuoteSlider;
