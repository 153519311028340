const ExpandableText = () => {
    const expandableHolder = document.querySelector('.expandable-text__inner');
    const expandableButton = document.querySelector('.expandable-text__button');
    const expandable = document.querySelector('.expandable-text');

    if (expandableHolder != null) {
        const expandableHolderHeight = expandableHolder.clientHeight;

        if (expandableHolderHeight > 400) {
            expandable.classList.add('closed');
            expandableButton.style.display = 'block'
        } else {
            expandable.classList.remove('closed');
            expandableButton.style.display = 'none'
        }

        expandableButton.addEventListener('click', (e) => {
            expandable.classList.toggle('closed')
            if (expandable.classList.contains('closed')) {
                e.currentTarget.innerHTML = 'Läs mer'
            } else {
                e.currentTarget.innerHTML = 'Stäng'
            }
        });
    }
}

export default ExpandableText;
