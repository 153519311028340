const MobileMenu = () =>
{
    const menuButton = document.querySelector('.js-menu-button');
    const menuOverlay = document.querySelector('.mobile-menu');
    const navLogo = document.querySelector('.site-navigationbar__logo');
    const navBorder = document.querySelector('.site-navigationbar__inner');
    const siteBody = document.querySelector('body');
    let checkState = false;

    menuButton.addEventListener('click', (e) => {
        let isOpen = !checkState;
        checkState = !checkState;

        if (isOpen) {
            menuButton.classList.add('is-active');
            menuOverlay.classList.add('open');
            navLogo.style.color = '#000';
            navBorder.style.borderBottom = 'none';
            siteBody.style.overflow = 'hidden';
        } else {
            menuButton.classList.remove('is-active');
            menuOverlay.classList.remove('open');
            navLogo.style.color = '';
            navBorder.style.borderBottom = '';
            siteBody.style.overflow = 'auto';
        }
    });
}

export default MobileMenu;
