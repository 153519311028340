const VideoPopupHandler = () => {
    const popupButton = document.querySelectorAll('.js-popup-btn');
    const popupWindow = document.querySelector('.js-popup');
    const popupClose = document.querySelector('.js-popup-close');
    let popupIframeSrc = document.querySelector('.js-popup-iframe');

    if (popupButton) {
        popupButton.forEach((button) => {
            console.log("click");
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const _this = e.currentTarget;
                const videoId = _this.getAttribute('data-video-id');
                popupIframeSrc.setAttribute('src', `${videoId}`);
                popupWindow.classList.add('is-playing');
            });
        });

        popupWindow.addEventListener('click', (e) => {
            e.target.classList.remove('is-playing');
            popupIframeSrc.setAttribute('src', '');
        });
    }
}

export default VideoPopupHandler;
