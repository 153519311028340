// Lib / Base
import '@babel/polyfill';
import './lib/picturefill.js';
import './lib/polyfills.js';
import Helpers from './lib/helpers.js';

// Components
import ExpandableText from './components/ExpandableText.js';
import TextSlider from './components/TextSlider.js';
import QuoteSlider from './components/QuoteSlider.js';
import YoutubeIframe from './components/YoutubeIframe.js';
import MobileMenu from './components/MobileMenu.js';
import FilterExercises from './components/FilterExercises.js';
import VideoPopup from './components/VideoPopup.js';
import FormHandler from './components/FormHandler.js';
import SocialShare from './components/SocialShare.js';
import './components/MenuScroll.js';

document.addEventListener('DOMContentLoaded', function()
{
    FormHandler();
    ExpandableText();
    TextSlider();
    QuoteSlider();
    YoutubeIframe();
    MobileMenu();
    FilterExercises();
    VideoPopup();
    SocialShare();
});
