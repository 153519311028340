import $ from 'jquery';

$(window).on('scroll', function()
{
    let winPos = $(this).scrollTop();
    const mainMenu = $('.site-navigationbar');

    if ( winPos > 10 && $(window).width() <= 960) {
        mainMenu.addClass('yellow');
    } else {
        mainMenu.removeClass('yellow');
    }
});
