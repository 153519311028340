const YoutubeIframe = () =>
{
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const youtubeScriptTag = document.getElementsByTagName('script')[0];
    youtubeScriptTag.parentNode.insertBefore(tag, youtubeScriptTag);

    const youtubeIframes = document.querySelectorAll('.js-youtube-iframe');
    const playButtons = document.querySelectorAll('.js-play-iframe');

    let player;
    function onYouTubeIframeAPIReady(currentIframe, currentIframeImage) {
        player = new YT.Player(currentIframe, {
            events: {
                'onReady': onPlayerReady
            }
        });
    }

    function onPlayerReady(event) {
        event.target.playVideo();
    }

    playButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            const playerTarget = e.currentTarget.getAttribute('data-target');
            const player = document.querySelector(`.js-youtube-iframe[data-id="${playerTarget}"]`);
            onYouTubeIframeAPIReady(player, e.currentTarget.parentNode);
            e.currentTarget.parentNode.style.display = 'none';
        })
    });
}

export default YoutubeIframe;
