const FormHandler = () =>
{
    if (document.querySelector('#registerForm')) {
        const registerForm = document.querySelector('#registerForm');
        const acceptButton = document.querySelector('.register-form__accept');
        const formSendButton = document.querySelector('.register-form__send-button');
        const formInputs = document.querySelectorAll('.register-form__input');
        const formConfirmHolder = document.querySelector('.register-form__confirm');
        const formConfirmTitle = document.querySelector('.register-form__confirm-title');

        const checkInputValues = (e) => {
            let allValid = false;

            formInputs.forEach((input, index) => {
                let isValid = input.checkValidity();

                if (!isValid) {
                    allValid = false;
                    input.style.border = '1px solid #eb573d';
                } else {
                    allValid = true;
                    input.style.border = '1px solid #3deb66';
                }
            });

            allValid ? formSubmission(e) : false;
        }

        const isChecked = () => {
            if (acceptButton.checked === true) {
                formSendButton.disabled = false;
            } else {
                formSendButton.disabled = true;
            }
        }

        const formSubmission = (e) => {
            e.preventDefault();

            formConfirmHolder.classList.remove('show');
            const request = new XMLHttpRequest();
            const formData = new FormData(registerForm);
            formData.append('action', 'sendRegisterForm');

            request.onreadystatechange = () => {
                request.readyState === 1 ? registerForm.classList.add('loading') : false;

                if (request.readyState == 4) {
                    if (request.status == 200) {
                        formConfirmHolder.classList.add('show');
                        formConfirmTitle.innerText = `Tack för din anmälan!`;
                    } else {
                        formConfirmHolder.classList.add('show');
                        formConfirmHolder.style.backgroundColor = '#f5350f';
                        formConfirmTitle.innerText = `Nånting gick fel, var god och försök igen!`;
                    }
                }
            };

            request.open('POST', AJAX_URL, true);
            request.send(formData);
        }

        acceptButton.addEventListener('click', (e) => isChecked());
        formSendButton.addEventListener('click', (e) => checkInputValues(e));
    }
}

export default FormHandler;
