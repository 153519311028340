const SocialShare = () =>
{
    var fbShare = function(url, title, descr, image, winWidth, winHeight)
    {
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        // window.open('https://www.facebook.com/sharer/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
        window.open(`https://www.facebook.com/dialog/feed?&app_id=471737243682490&link=${url}&display=popup`);
    }

    if (document.querySelector('.js-facebook')) {
        document.querySelector('.js-facebook').addEventListener('click', (e) => {
            e.preventDefault();

            var _this = e.currentTarget;
            var url = _this.getAttribute('href');
            var title = _this.getAttribute('data-title');
            var desc = _this.getAttribute('data-desc');
            var image = _this.getAttribute('data-image');

            fbShare(url, title, desc, image, 520, 350);
        });
    }
}

export default SocialShare;
