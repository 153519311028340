import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';

const TextSlider = () =>
{
    jQueryBridget( 'flickity', Flickity, $ );

    const textSliderHolder = document.querySelectorAll('.text-slider__slider-holder');

    if ( textSliderHolder.length != 0 ) {
        textSliderHolder.forEach((slider) => {
            var $gallery = $(slider).flickity({
                cellSelector: '.text-slider__slide',
                wrapAround: false,
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'center',
                autoPlay: false,
                draggable: true,
                selectedAttraction: 0.2,
                friction: 0.8
            });

            window.addEventListener( 'load', function() { flkty.resize() });

            const flkty = $gallery.data('flickity');
            const $cellButtonGroup = $('.text-slider__buttons');
            const $cellButtons = $cellButtonGroup.find('.text-slider__button-holder');

            flkty.on( 'cellSelect', function() {
              $cellButtons.filter('.is-selected').removeClass('is-selected');
              $cellButtons.eq( flkty.selectedIndex ).addClass('is-selected');
            });

            $cellButtonGroup.on( 'click', '.text-slider__button-holder', function() {
              const index = $(this).index();
              $gallery.flickity( 'select', index );
            });
        });
    }
}

export default TextSlider;
