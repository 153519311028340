const FilterExercises = () =>
{
    const filterButtons = document.querySelectorAll('.exercise-list__filter-button');
    const exerciseItems = document.querySelectorAll('.exercise-list__list-item');
    const filterSelect = document.querySelector('.excercise-list__filter-select');

    if (filterButtons.length > 0)
    {
        function filterAllExercises(filterId) {
            exerciseItems.forEach((exercise, index) => {
                const filterMatch = exercise.getAttribute('data-terms').split(',').includes(filterId);
                filterMatch ? exercise.style.display = 'flex' : exercise.style.display = 'none';
            });
        }

        filterButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                button.classList.add('active');
                filterAllExercises(e.currentTarget.getAttribute('data-filter-id'));
            });
        })

        filterSelect.addEventListener('change', (e) => {
            filterAllExercises(e.target.options[e.target.options.selectedIndex].value);
        })
    }
}

export default FilterExercises;
